<template>
    <div class="popup_overlay px-4" v-if="imageDetail !== null">
    <div class="custom_dialog" style="width: 600px;max-height: 80vh;">
      <div class="relative">
        <div>
          <div class="bg-primary flex justify-between p-3">
            <div class=" font-medium text-white heading-3 overFlowParaA">
                {{title}}
            </div>
            <div class="flex items-center">
              <div class=" text-primary heading-3 cursor-pointer bg-white px-3 py-1 rounded-xl ml-2" @click="closePopup(false)">
                <i class="fas fa-times"></i>
              </div>
              <div class=" text-primary heading-3 cursor-pointer bg-white px-3 py-1 rounded-xl ml-2" @click.stop="showMenuList = true">
                <i class="fas fa-ellipsis-v"></i>
              </div>
              <div v-if="showMenuList">
                <div v-click-outside="closeList">
                  <div class=" bg-white absolute z-10 right-3 shadow-2xl rounded top-10 w-44">
                      <div class="p-2 text-text2 font-medium text-center">
                        <div class="p-2 cursor-pointer hover:bg-blue-50" @click="ingorePost();showMenuList = false">
                          <span class="py-1">Ignore Request</span>
                        </div>
                        <div class="p-2 cursor-pointer hover:bg-red-50"  @click="deletePost();showMenuList = false">
                          <span class="py-1 text-error">Delete Shot</span>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="overflow: auto;max-height: 70vh;">
            <div v-if="imageDetail !== null">
              <div v-if="imageDetail.postType === 'image'" class="p-2 flex justify-center">
                <img :style="`width: auto;height:38vh;max-height:55%`" class=" w-auto" :src="imageDetail.postUrl" alt="">
              </div>
              <div v-else class="flex items-center mb-4 place-content-center">
                <video id="myVideo" autoplay class="mt-1" loop controls :style="`width: 80%;height:38vh;max-height:55%`">
                <source  type="video/mp4" align="center" justify="center" class="ma-0" :src="imageDetail.postUrl">
                </video>
              </div>
            </div>
            <div class="p-4" v-if="imageDetail !== null">
              <div class="mb-2 items-center flex">
                <p class="text-gray4 font-semibold heading-6 fixWidth">Reported Date</p>
                <span class="text-gray4 heading-4 pl-2">:</span>
                <p class="font-semibold heading-5 text-text2 pl-4">{{imageDetail.reportedOn | dateTimeFilter}}</p>
              </div>
              <div class="mb-2 items-center flex">
                <p class="text-gray4 font-semibold heading-6 fixWidth">Reported By</p>
                <span class="text-gray4 heading-4 pl-2">:</span>
                <p class="font-semibold heading-5 text-text2 pl-4">{{imageDetail.reportedby !== '' ? imageDetail.reportedby : '-'}}</p>
              </div>
              <div class="mb-2 items-center flex">
                <p class="text-gray4 font-semibold heading-6 fixWidth">Location</p>
                <span class="text-gray4 heading-4 pl-2">:</span>
                <p class="font-semibold heading-5 text-text2 pl-4">{{imageDetail.location !== '' ? imageDetail.location : '-'}}</p>
              </div>
              <div class="flex">
                <p class="text-gray4 font-semibold heading-6 fixWidth">Tagged User</p>
                <span class="text-gray4 heading-4 pl-2">:</span>
                <span  v-if="tagList.length > 0">
                  <div class="font-semibold heading-5 text-primary pl-4" v-for="(data, index) in tagList" :key="index">
                    @{{ data.userName }}
                  </div>
                </span>
                <span v-else class="font-semibold heading-5 text-text2 pl-4">-</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="confirmPopup" class="popup_overlay relative px-4">
      <div style="width: 500px;" class="custom_dialog rounded-xl h-min p-4">
        <div  class="mb-4 font-semibold">
          <p class="heading-4 text-text1">{{isPostDelete ? 'Remove Post?' : 'Ignore Report?'}}</p>
        </div>
        <div  class="mb-5 font-semibold">
          <p class="heading-5 text-text1 mb-4">Are you sure you want to {{isPostDelete ? 'remove' : 'ignore'}} this post?</p>
        </div>
        <div class="flex gap-2 justify-end">
          <button class="heading-5 text-white px-3 py-1 rounded bg-gray4" @click="confirmPopup = false">Cancel</button>
          <button class="heading-5 text-white px-3 py-1 rounded bg-primary" @click="actionOnBtn()" v-if="!isPostDelete">Yes</button>
          <button class="heading-5 text-white px-3 py-1 rounded bg-error" @click="actionOnBtn()" v-if="isPostDelete">Remove</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ADMINAPI from '@/View/Admin/api/Admin.js'
export default {
  name: 'sendEmail',
  components: {
  },
  data () {
    return {
      title: '',
      tagList: [],
      showMenuList: false,
      imageDetail: null,
      srcUrl: '',
      imageDivHeight2: '',
      isPostDelete: false,
      confirmPopup: false,
    }
  },
  props: ["object"],
   created() {
  },
  mounted () {
    this.imageDivHeight2 = window.innerHeight - 300
    console.log('imageDivHeight2', this.imageDivHeight2, window.innerHeight)
    this.getReportedShortDetail()
    // this.srcUrl = this.object.postUrl
    // console.log('srcUrl', this.srcUrl);
    document.body.style = 'overflow: hidden;'
  },
  watch: {},
  methods: {
    closeList () {
      this.showMenuList = false
    },
    getReportedShortDetail () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      ADMINAPI.GetReportedShotsDetail(
        this.object.postsReportId,
        response => {
          this.imageDetail = response.Data === null ? {} : response.Data
          this.tagList = response.Data.taggedUser.userTagList === null ? [] : response.Data.taggedUser.userTagList
          this.title = this.imageDetail.postUserName
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    ingorePost() {
      this.isPostDelete = false
      this.confirmPopup = true
    },
    deletePost () {
      this.isPostDelete = true
      this.confirmPopup = true
    },
    actionOnBtn () {
      if (this.isPostDelete) {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
        ADMINAPI.DeleteReportedPost(
          this.imageDetail.postId,
          this.imageDetail.fileVaultId,
          this.imageDetail.postUserDetailId,
          response => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$root.$emit('fullImageHandler', true)
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      } else {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
        ADMINAPI.ignoreReportedPost(
          this.object.postId,
          response => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$root.$emit('fullImageHandler', true)
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      }
    },
    closePopup (data) {
      document.body.style = 'overflow: visible;'
      this.$root.$emit('fullImageHandler', data)
    },
  },
  beforeDestroy () {}
}
</script>
<style scoped>
.imageFullScreen {
  width: auto;
  max-height: 100%;
  justify-content: center;
  align-items: center;
}
.fixWidth {
  width: 94px;
  min-width: 94px;
}
</style>