<template>
  <div class="card p-2" style="height: 90vh;overflow: auto;">
    <div class="heading-3 text-text1">● Reported Shot{{totalEmpCount > 0 ? 's' : ''}}: <span class="font-bold heading-2 text-text2">{{totalEmpCount}}</span></div>
    <div class="rounded py-2 pt-4 bg-white">
      <div class="relative card p-3 rounded-xl" v-if="sortList.length > 0">
        <div class="flex gap-8" style="flex-wrap: wrap;">
          <div class=" card rounded-xl flex border-t border-gray-100 bg-white attachment-card cursor-pointer justify-center" style="width: 220px; height: 180px"  v-for="(attach, a) in sortList " :key="a" @click="showFullImage(attach)">
            <div v-if="attach.postType === 'image'" class="text-center">
              <img style="height: 100%;width: auto;object-fit: contain;" :src="attach.postUrl" class="scroll-img-view">
            </div>
            <div v-if="attach.postType === 'video'"  class="flex justify-center items-center w-full">
              <video id="myVideo" autoplay loop class="mt-1" style="height: 100%;width: auto;object-fit: contain;">
                <source type="video/mp4" align="center" justify="center" class="ma-0" :src="attach.postUrl">
              </video>
            </div>
          </div>
        </div>
        <div class="text-center pt-10" v-if="showLoadMore">
          <button class="ml-3 px-3 py-1 card bg-primary font-semibold text-white rounded-md cursor-pointer" @click="loadMoreHistory()">Load more....</button>
        </div>
      </div>
      <div class="layout my-2" v-if="showHistoryWarning">
        <div class="flex p-3 text-sm text-primary bg-blue-100 rounded-lg  items-center" role="alert">
          <svg aria-hidden="true" class="flex-shrink-0 inline w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
          <span class="sr-only">Info</span>
          <div>
            <p class=" font-medium heading-4">No record found.</p>
          </div>
        </div>
      </div>
    </div>
    <FullImage v-if="shoFullImage" :object="selectedDetail" />  
  </div>
</template>
<script>
import FullImage from '@/View/Admin/shorts/FullImage.vue'
import ADMINAPI from '@/View/Admin/api/Admin.js'
export default {
props: ['detail'],
components: {
  FullImage
},
data () {
  return {
    totalEmpCount: 0,
    showLoadMore: true,
    confirmPopup: false,
    sortList: [],
    shoFullImage: false,
    showHistoryWarning: false,
    selectedDetail: {},
    totalLength: 20,
    communicationStart: 0,
  }
},
 created() {
},
mounted () {
  this.getReportedShortList()
  document.body.style = 'overflow: hidden;'
  this.$root.$on('fullImageHandler', (data) => {
    if (data) {
      this.communicationStart = 0

      this.getReportedShortList()
    }
    this.shoFullImage = false
  })
},
watch: {},
methods: {
  loadMoreHistory () {
    this.communicationStart = this.sortList.length
    this.getReportedShortList()
  },
  getReportedShortList () {
    this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
    ADMINAPI.GetReportedShots(
        this.communicationStart,
        this.totalLength,
        response => {
          // console.log("response", response.Data)
          // this.sortList = response.Data.tableRow === null ? [] : response.Data.tableRow
          this.totalEmpCount = response.Data.count
          if (this.communicationStart === 0) {
            this.sortList = []
          }
          this.showLoadMore = true
          let temp = response.Data.tableRow === null ? [] : response.Data.tableRow
          for (let i = 0; i < temp.length; i++) {
            this.sortList.push(response.Data.tableRow[i])
          }
          if (this.sortList.length === 0) {
            this.showHistoryWarning = true
          } else {
            this.showHistoryWarning = false
          }
          if (temp.length === 0 || this.sortList.length < 20) {
            this.showLoadMore = false
          }
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
  },
  showFullImage (data) {
    this.selectedDetail = data
    this.shoFullImage = true
  },
},
beforeDestroy () {
  // this.$root.$off('closeProfileDialog')
}
}
</script>
<style scoped>
.row_user_svg {
  height: 120px;
  width: auto;
}
</style>